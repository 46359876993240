<template>
  <div>
    <header class="hero-int">
      <div class="bb-container">
        <div class="hero-int__container">
          <div class="hero-int__left">
            <h1 class="hero-int__title">
              {{ $t("home2.title") }}
            </h1>
            <div class="hero-int__actions">
              <bbButton
                :label="$t('home2.cta')"
                class="button--lg button--accent"
              />
              <router-link to="#" class="link link--bold link--underline">
                {{ $t("home2.example") }}
              </router-link>
            </div>
          </div>
          <div class="hero-int__cover">
            <picture>
              <source
                srcset="../../assets/img/covers/cover-fr-hero@2x.png"
                media="(min-width: 1399px) and (min-device-pixel-ratio: 2)"
              />
              <img src="../../assets/img/covers/cover-fr-hero.png" alt="" />
            </picture>
          </div>
        </div>
      </div>
    </header>
    <section class="rate-app">
      <div class="bb-container">
        <div class="rate-app__container">
          <div class="rate-app__title" v-html="$t('home2.moreThan')"></div>
          <div class="rate-app__card">
            <div class="rate-app__item">
              <div class="rate-app__logo">
                <img src="../../assets/img/ico/ico-google-play.svg" alt="Google Play" />
              </div>
              <div class="rate-app__rate">4,8</div>
              <div class="rate-app__star">
                <img src="../../assets/img/ico/ico-star-fill.svg" alt="" />
              </div>
            </div>
            <hr />
            <div class="rate-app__item">
              <div class="rate-app__logo">
                <img src="../../assets/img/ico/ico-app-store.svg" alt="Apple App Store" />
              </div>
              <div class="rate-app__rate">4,8</div>
              <div class="rate-app__star">
                <img src="../../assets/img/ico/ico-star-fill.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="tiendas-list">
      <div class="tiendas-list__title">{{ $t("home2.addFrom") }}</div>
      <div class="tiendas-list__items">
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-af-amazon.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-berceau-magique.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-autour-bebe.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-petit-bateuau.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-vertbaudet.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-maisons-du-monde.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-aubert.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-orchestra.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-armoire-de-bebe.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-smallable.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-bebe-au-naturel.svg" />
        </div>
        <div class="tiendas-list__item">
          <img loading="lazy" src="../../assets/img/logos/logo-fr-kiavi.svg" />
        </div>
      </div>
    </section>
    <section class="hbb-features">
      <div class="bb-container">
        <div class="hbb-features__title" v-html="$t('home2.reinventedList')"></div>
        <div class="hbb-features__items">
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-list-heart.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">{{ $t("home2.reason1Title") }}</div>
              <div class="hbb-features__sh">
                {{ $t("home2.reason1Text") }}
              </div>
            </div>
          </div>
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-click-mobile.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">{{ $t("home2.reason2Title") }}</div>
              <div class="hbb-features__sh">
                {{ $t("home2.reason2Text") }}
              </div>
            </div>
          </div>
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-gratuit.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">{{ $t("home2.reason3Title") }}</div>
              <div class="hbb-features__sh">
                {{ $t("home2.reason3Text") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="app-avanzada">
      <div class="bb-container">
        <div class="app-avanzada__container"></div>
        <div class="app-avanzada__left">
          <div class="app-avanzada__title">{{  $t("home2.appTitle")  }}</div>
          <div class="app-avanzada__description">
            {{ $t("home2.appText") }}
          </div>
          <div class="app-avanzada__actions">
            <a href="#" class="link link--bold link--underline">
              Descubre nuestra App gratuita
            </a>
            con todo lo que necesitas para hacer tu lista perfecta.
          </div>
          <div class="app-avanzada__stores">
            <a href="#">
              <img src="../../assets/img/logos/logo-app-google.svg" alt="" />
            </a>
            <a href="#">
              <img src="../../assets/img/logos/logo-app-appstore.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
    <main class="home-beneficios">
      <div class="bb-container">
        <div class="home-beneficios__items">
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img src="../../assets/img/covers/cover-fr-heart-sale.png" alt="" />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                {{ $t("home2.value1Title") }}
              </div>
              <div class="home-beneficios__description">
                {{ $t("home2.value1Text") }}
              </div>
              <ul class="home-beneficios__list">
                <li>{{ $t("home2.value1Text1") }}</li>
                <li>{{ $t("home2.value1Text2") }}</li>
              </ul>
            </div>
          </section>
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img src="../../assets/img/covers/cover-caja-hellobb-mano.jpg" alt="" />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                {{ $t("home2.value2Title") }}
              </div>
              <div class="home-beneficios__description">
                {{ $t("home2.value2Text")}}
              </div>
              <div class="home-beneficios__actions">
                <div class="link link--bold link--underline">{{ $t("home2.value2Link") }}</div>
              </div>
            </div>
          </section>
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img src="../../assets/img/covers/cover-caja-hellobb-mano.jpg" alt="" />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                {{ $t("home2.value3Title") }}
              </div>
              <div class="home-beneficios__description">
                {{ $t("home2.value3Text") }}
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
    <div class="feedback-int">
      <div class="bb-container">
        <div class="feedback-int__title">{{ $t("home2.commentsTitle") }}</div>
        <div class="feedback-int__items">
          <div class="feedback-user">
            <div class="feedback-user__cover">
              <img src="../../assets/img/covers/testimonial-marta.jpg" alt="" />
            </div>
            <div class="feedback-user__content">
              <div class="feedback-user__string">
                {{ $t("home2.comments1") }}
              </div>
              <div class="feedback-user__name">Marta Cintas González</div>
            </div>
          </div>
          <div class="feedback-user">
            <div class="feedback-user__cover">
              <img src="../../assets/img/covers/testimonial-estefania.jpg" alt="" />
            </div>
            <div class="feedback-user__content">
              <div class="feedback-user__string">
                {{ $t("home2.comments2") }}
              </div>
              <div class="feedback-user__name">Estefanía C.</div>
            </div>
          </div>
          <div class="feedback-user">
            <div class="feedback-user__cover">
              <img src="../../assets/img/covers/testimonial-ichamar.jpg" alt="" />
            </div>
            <div class="feedback-user__content">
              <div class="feedback-user__string">
                {{ $t("home2.comments3") }}
              </div>
              <div class="feedback-user__name">Ichamar</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="acomp">
      <div class="bb-container">
        <div class="acomp__container">
          <div class="acomp__left">
            <div class="acomp__title">{{ $t("home2.goAlongWithYouTitle") }}</div>
            <div class="acomp__description">
              <p>
                {{ $t("home2.goAlongWithYouText1") }}
              </p>
              <p>
                {{ $t("home2.goAlongWithYouText2") }}
              </p>
            </div>
          </div>
          <div class="acomp__right">
            <img src="../../assets/img/covers/cover-caja-hellobb-mano.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="cta-yellow-fw">
      <div class="bb-container">
        <div class="cta-yellow-fw__cta">
          <div class="cta-yellow-fw__left">
            <div class="cta-yellow-fw__title">
              {{ $t("home2.bottomTitle") }}
            </div>
            <div class="cta-yellow-fw__actions">
              <bbButton
                :label="$t('home2.cta')"
                class="button--accent button--lg"
              />
              <div class="link link--bold link--underline">{{ $t("home2.example") }}</div>
            </div>
          </div>
          <div class="cta-yellow-fw__cover">
            <img src="../../assets/img/covers/cover-peluche-ropa-bb-comoda.jpg" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import bbButton from "../../components/buttons/bbButton.vue";

export default {
  name: "HomePageFR",
  metaInfo() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            name: "HelloBB",
            operatingSystem: [
              //no estic segur del format per posar més d’un valor
              "ANDROID",
              "IOS",
            ],
            applicationCategory: "LifestyleApplication",
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.5", //no aconsegueixo veure ratings, tu pots?
              ratingCount: "20", //no aconsegueixo veure ratings, tu pots?
            },
            offers: {
              "@type": "Offer",
              price: "0",
            },
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "HelloBB",
            legalName: "HelloBB S.L.",
            url: "https://www.hellobb.net/fr",
            logo: "https://www.hellobb.net/logo-hellobb-dark.svg", //posar URL on tenim el logo
            description:
              "Demandez des articles de n’importe quelle boutique et recevez des cadeaux ou de l’argent grâce à la liste de naissance la plus belle et la plus facile à utiliser.",
            foundingDate: "2022",
            address: {
              "@type": "PostalAddress",
              streetAddress: "C/ Pompeu Fabra 36",
              addressLocality: "Tortosa",
              addressRegion: "Tarragona",
              postalCode: "43500",
              addressCountry: {
                "@type": "Country",
                name: "Spain", //en anglés millor?
              },
            },
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "customer support",
              email: "hola@hellobb.net",
            },
            sameAs: [
              "https://www.instagram.com/holahellobebe/",
              "https://www.facebook.com/holahelloBB",
              "https://www.pinterest.es/holahelloBB/",
              "https://www.crunchbase.com/organization/hellobb",
            ],
          },
        },
      ],
      title: "HelloBB | Una liste de naissance au top comme votre famille",
      meta: [
        {
          name: "description",
          content:
            "Demandez des articles de n’importe quelle boutique et recevez des cadeaux ou de l’argent grâce à la liste de naissance la plus belle et la plus facile à utiliser.",
        },
        {
          itemprop: "image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
        {
          property: "og:url",
          content: "https://www.hellobb.net/fr",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "HelloBB | Una liste de naissance au top comme votre famille",
        },
        {
          property: "og:description",
          content:
            "Demandez des articles de n’importe quelle boutique et recevez des cadeaux ou de l’argent grâce à la liste de naissance la plus belle et la plus facile à utiliser.",
        },
        {
          property: "og:image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "HelloBB | Una liste de naissance au top comme votre famille",
        },
        {
          name: "twitter:description",
          content:
            "Demandez des articles de n’importe quelle boutique et recevez des cadeaux ou de l’argent grâce à la liste de naissance la plus belle et la plus facile à utiliser.",
        },
        {
          name: "twitter:image",
          content: "https://www.hellobb.net/images/cover-portada.08a0882.png",
        },
      ],
      link: [
        { rel: "canonical", href: "https://www.hellobb.net" },
        { rel: "alternate", href: "https://www.hellobb-net/fr", hreflang: "fr"},
        { rel: "alternate", href: "https://www.hellobb.net", hreflang: "es-es" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-mx" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-co" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-ar" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-cl" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-uy" },
        { rel: "alternate", href: "https://www.hellobb.net/latam", hreflang: "es-pe" },
        { rel: "alternate", href: "https://www.hellobb.net/fr", hreflang: "fr"},
      ],
    };
  },
  components: {
    bbButton,
  },
};
</script>

<style lang="scss" scoped></style>
